<template>
  <div class="tags" v-if="showTags">
    <ul class="vd_ul">
      <li class="tags-li" v-for="(item, index) in tagsList" :class="{ active: isActive(item.path) }" :key="index">
        <router-link :to="item.path" class="tags-li-title" @click.native="flushDashboardData(item.path)">
          {{ item.title }}
        </router-link>
        <span class="tags-li-icon" @click="closeTags(index)"><i class="el-icon-close"></i></span>
      </li>
    </ul>
    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <el-button size="mini" type="primary"> 标签选项<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import bus from '@/components/common/bus';
export default {
  data() {
    return {
      tagsList: []
    };
  },
  methods: {
    flushDashboardData(val) {
      let str = val.substr(1, val.length);
      this.$emit('setSideMenu', str);
      if (this.$route.name !== 'dashboard') return;
      this.$store.commit('setDashboardData', !this.$store.state.reGetDashBoardData);
    },
    isActive(path) {
      return path === this.$route.fullPath;
    },
    // 关闭单个标签
    closeTags(index) {
      if (this.tagsList.length === 1 && this.tagsList[0].name === 'Home') {
        console.log(1123123);
      } else {
        const delItem = this.tagsList.splice(index, 1)[0];
        const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];

        if (item) {
          delItem.path === this.$route.fullPath && this.$router.push(item.path);
        } else {
          this.$router.push('/');
        }
      }
    },
    // 关闭全部标签
    closeAll() {
      if (this.tagsList.length === 1 && this.tagsList[0].name === 'Home') {
        console.log(111);
      } else {
        this.tagsList = [];
        this.$router.push('/');
      }
    },
    // 关闭其他标签
    closeOther() {
      const curItem = this.tagsList.filter(item => {
        return item.path === this.$route.fullPath;
      });
      this.tagsList = curItem;
    },
    // 设置标签
    setTags(route) {
      const isExist = this.tagsList.some(item => {
        return item.path === route.fullPath;
      });
      if (!isExist) {
        // if(this.tagsList.length >= 8){
        //   this.tagsList.shift();
        // }
        this.tagsList.push({
          title: route.meta.title,
          path: route.fullPath,
          name: route.matched[1].components.default.name
        });
      }
      bus.$emit('tags', this.tagsList);
    },
    handleTags(command) {
      command === 'other' ? this.closeOther() : this.closeAll();
    }
  },
  computed: {
    showTags() {
      return this.tagsList.length > 0;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.setTags(newValue);
    }
  },
  created() {
    this.setTags(this.$route);
    // 监听关闭当前页面的标签页
    this.$EventBus.$on('close_current_tags', () => {
      let index = this.tagsList.findIndex(x => x.path === this.$route.fullPath);
      this.closeTags(index);
    });
  }
};
</script>

<style lang="scss">
.tags {
  position: relative;
  height: 30px;
  overflow: hidden;
  background: white;
  padding-right: 120px;
  box-shadow: 0 5px 10px #ddd;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.tags-li {
  float: left;
  margin: 3px 5px 2px 3px;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 23px;
  line-height: 23px;
  border: 1px solid #e9eaec;
  background: white;
  padding: 0 5px 0 12px;
  vertical-align: middle;
  color: $color-title-list;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
  background: $color-tags-list;
}

.tags-li .active {
  color: $color-white;
}

.tags-li-title {
  float: left;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: $color-title-list;
}

.tags-li.active .tags-li-title {
  color: $color-white;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 110px;
  height: 30px;
  background: white;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.vd_ul {
  overflow-x: auto;
}
</style>
