<template>
  <div class="wrapper">
    <!--    v-if="showSHT === true"-->
    <div>
      <vHead></vHead>
      <vSidebar :sideMenu="sideMenu" ref="vSidebar"></vSidebar>
      <!--    <el-divider class="vd_color"></el-divider>-->
      <div class="content-box" :class="{ 'content-collapse': collapse }">
        <vTags @setSideMenu="setSideMenu"></vTags>
        <div class="content">
          <transition name="move" mode="out-in">
            <keep-alive :include="tagsList">
              <router-view :key="$route.fullPath"></router-view>
            </keep-alive>
          </transition>
          <el-backtop target=".content" :bottom="100"></el-backtop>
        </div>
      </div>
    </div>
    <!--    <div v-if="showSHT === false">-->
    <!--      <router-view></router-view>-->
    <!--    </div>-->
  </div>
</template>

<script>
import vSidebar from '@/components/SideMenu.vue';
import vHead from '@/components/TopHeader.vue';
import vTags from '@/components/Tags.vue';
import bus from '@/components/common/bus';
import {get} from '@api/request';
import {debounce} from 'lodash';
import {apprAPI} from '@api/modules/appr';

export default {
  data() {
    return {
      tagsList: [],
      collapse: false,
      showSHT: false,
      mode: null,
      sideMenu: '',
      apprNeedList: []
    };
  },
  components: {
    vHead,
    vSidebar,
    vTags
  },
  watch: {
    // $route(to){
    //   if(to.fullPath === '/login') {
    //     this.showSHT = false;
    //     console.log('this.showSHT',this.showSHT)
    //   } else {
    //     this.showSHT = true;
    //   }
    // }
  },
  created() {
    bus.$on('collapse-content', msg => {
      this.collapse = msg;
    });
    this.getUserMode();
    // this.getProc()

    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on('tags', msg => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });
  },
  mounted() {
    this.initWebSocket();
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log('socket success');
    },
    // 接收消息
    onmessage(e) {
      let userId = this.$cookies.get('userInfo').user_id;
      //委托接收单接收提醒
      if (e.data === userId + '') {
        this.notify({
          title: '接收提醒',
          children: [
            this.content({msg: '有新的委托接收单'}),
            this.contentBtn({
              title: '查看详情',
              method: () => this.goToRequList()
            })
          ]
        });
        //刷新首页推送数量
        bus.$emit('refreshReminderCount');
        //刷新首页推送list
        bus.$emit('refreshRequPush9004');
        bus.$emit('dashRequPush9004');
        return;
      }
      //审批通知（老代码）
      if (e.data.indexOf('提交') !== -1) {
        this.$notify.closeAll();
        // 获取待审批记录 节流
        this.getIApprove(e);
        return;
      }
      //实时提醒（新版）
      let data = JSON.parse(e.data);
      if (Number(data.user_id) === Number(userId)) {
        switch (data.status) {
            //退样提醒
          case 1:
            this.notify({
              title: '退样提醒',
              children: [
                this.content({data: {'white-space': 'pre-wrap'}, msg: data.appr_inst_info}),
                this.contentBtn({
                  title: '查看详情',
                  method: () => this.goToSample(data)
                })
              ]
            });
            //刷新首页推送list
            bus.$emit('refreshRequPush9005');
            break;
            //工艺信息完善提醒
          case 2:
            this.notify({
              title: '工艺信息完善提醒',
              children: [
                this.content({data: {'white-space': 'pre-wrap'}, msg: data.appr_inst_info}),
                this.contentBtn({
                  title: '去完善',
                  method: () => this.goToSample(data)
                })
              ]
            });
            //浏览器提醒
            this.showJudge(data);
            //刷新首页list
            bus.$emit('refreshSmplPush9006');
            break;
          case 3:
            this.notify({
              title: '样品信息更新提醒',
              children: [
                this.content({data: {'white-space': 'pre-wrap'}, msg: data.appr_inst_info}),
                this.contentBtn({
                  title: '查看详情',
                  method: () =>
                      this.jump('/docu_push_list_9007')
                })
              ]
            });
            //浏览器提醒
            this.showJudge(data);
            //刷新首页list
            bus.$emit('refreshPush9007');
            break;
          case 4:
            this.notify({
              title: '取消打样提醒',
              children: [
                this.content({data: {'white-space': 'pre-wrap'}, msg: data.appr_inst_info}),
                this.contentBtn({
                  title: '查看详情',
                  method: () =>
                      this.jump('/smpl_list', {
                        perm_id: data.perm_id,
                        requ_no: data.appr_inst_url
                      })
                })
              ]
            });
            //浏览器提醒
            this.showJudge(data);
            //刷新首页list
            bus.$emit('refreshPush9008');
            break;
          case 5:
            this.notify({
              title: '报价单退回 提醒',
              children: [
                this.content({data: {'white-space': 'pre-wrap'}, msg: data.appr_inst_info}),
                this.contentBtn({
                  title: '查看详情',
                  method: () =>
                      this.jump('/quta_edit', {
                        perm_id: data.perm_id,
                        form_id: data.form_id
                      })
                })
              ]
            });
            break;
        }
        //刷新首页推送数量
        bus.$emit('refreshReminderCount');
      }
    },
    // 连接报错
    onerror() {
    },
    // 关闭连接
    onclose() {
      console.log('socket close');
    }
  },
  methods: {
    initWebSocket() {
      let userId = this.$cookies.get('userInfo').user_id;
      let str = this.helper.socketUrl(userId);
      this.$connect(str);
    },
    //跳转样品工单
    goToSample(data) {
      this.jump('/smpl_edit', {
        perm_id: data.perm_id,
        form_id: data.form_id
      });
    },
    //跳转委托接收单List
    goToRequList() {
      this.jump('/requ_list', {
        perm_id: 111
      });
    },
    /**
     * @param {Array} children VNode
     * @param {title} title 標題
     * @returns {VNode}
     */
    notify({title = '', children = []}) {
      this.$notify({
        duration: 0,
        title: title,
        message: this.$createElement(
            'div',
            {
              style: {
                display: 'flex',
                width: '100%'
              }
            },
            children
        )
      });
    },
    /**
     * @param {String} msg msg
     * @param {title} title 標題
     * @returns {VNode}
     */
    content({data = {}, msg = ''}) {
      return this.$createElement('p', {style: data}, msg);
    },
    /**
     * @param {method} method method
     * @param {String} title 標題
     * @returns {VNode}
     */
    contentBtn({
                 title = '', method = (method = () => {
      })
               }) {
      return this.$createElement(
          'el-button',
          {
            style: {'margin-left': 'auto', height: '28px', 'margin-top': 'auto'},
            attrs: {size: 'mini', type: 'primary'},
            on: {
              click: method
            }
          },
          title
      );
    },
    /**
     * @param {Function} method function
     * @param {String} msg 消息
     * @returns {VNode}
     */
    contentLink({
                  method = () => {
                  }, msg = ''
                }) {
      return this.$createElement(
          'el-link',
          {
            attrs: {size: 'mini', type: 'primary', underline: false},
            on: {
              click: method
            }
          },
          msg
      );
    },
    setSideMenu(val) {
      this.sideMenu = val;
    },
    getIApprove: debounce(function (e) {
      get(apprAPI.getIApprove).then(res => {
        if (res.data.code === 0) {
          let data = e.data;
          if (data.indexOf('提交') !== -1) {
            let dataName = data.substring(data.indexOf('提交'), data.indexOf('提交') + 3);
            let userId = this.$cookies.get('userInfo').user_id;
            let dataUserId = data.substring(data.indexOf(';') + 1, data.indexOf('#'));
            let userIdList = data.substring(data.indexOf('#') + 1, data.indexOf(',')).split('&');
            if (dataName === '提交1') {
              if (res.data.data[0].count > 0) {
                let flag = false;
                userIdList.forEach(item => {
                  if (Number(item) === userId) {
                    flag = true;
                  }
                });
                if (flag) {
                  this.$notify({
                    title: '审批提醒',
                    message: `有新的${res.data.data.length > 0 ? res.data.data[0].count : 0}条需审批消息`,
                    duration: 0
                  });
                }
              }
            } else if (dataName === '提交2') {
              if (Number(userId) === Number(dataUserId)) {
                this.$notify({title: '审批提醒', message: '有新的被审批消息', duration: 0});
              }
            }
            bus.$emit('onmessage', dataName);
            // }
          }
        }
      });
    }, 500),
    // getProc(){
    //   get(procAPI.get_user_proc)
    //   .then(res =>{
    //     if(res.data.code!==0){

    //       return this.$message.error(res.data.msg)
    //     }
    //     this.procList = res.data.data.form.proc_codes.split(',')
    //   })
    //   .catch(res =>{})
    // },
    getUserMode() {
      this.mode = this.$cookies.get('userMode').type;
      if (this.mode === 1) {
        this.collapse = true;
      }
    },
    //浏览器提醒
    showJudge(data) {
      // 判断是否支持显示
      if (!('Notification' in window)) {
        alert('抱歉，此浏览器不支持桌面通知！');
      }
      // granted: 用户允许该网站发送通知 default: 默认值，用户还未选择 denied: 用户拒绝该网站发送通知
      // Notification.permission === 'granted' 可用于检测用户通知权限
      Notification.requestPermission().then(result => {
        if (result === 'denied') {
          console.log('用户拒绝');
          return;
        } else if (result === 'default') {
          console.log('用户未授权');
          return;
        }
        this.sendMesgToDesk(data);
      });
    },
    // 浏览器显示消息通知
    sendMesgToDesk(data) {
      let notification = null;
      let title = '审批提醒';
      let appr_inst_info = data.appr_inst_info;
      let str1 = appr_inst_info.replace(/<span style='color:red'>(.*?)<\/span>/g, (match, p1) => p1);
      let options = {
        tag: data.user_id, // 多条消息时tag相同只显示一条通知，需要显示多条时tag一定要不同，（谷歌每次只能显示一条，火狐可以显示多条）
        body: str1, // 通知主体
        // data: {
        //   // 可以放置任意数据，方便后续使用
        //   content: data.data,
        //   originUrl: `http://localhost:8889/#/home`
        // },
        requireInteraction: true // 不自动关闭通知 默认值为false，通知会在三四秒之后自动关闭，（谷歌有用，火狐依然会自动关闭）
      };
      notification = new Notification(title, options);
      // 事件处理
      notification.onclick = ({currentTarget: {data}}) => {
        // notification.close() 单个通知关闭
        window.focus();
        // 默认进入系统之前打开的页面，也可以这里自定义进入的页面
        // this.goAppr(data.appr_inst_url);
      };
      notification.onshow = () => {
        console.log('通知显示了');
      };
      notification.onclose = () => {
        console.log('通知被关闭了');
      };
      notification.onerror = () => {
        console.log('遇到错误');
      };
    }
  }
};
</script>

<style scoped lang="scss">
.vd_color {
  background-color: $color-primary;
  height: 2px;
}
</style>
