<template>
  <div class="vd_header">
    <el-row class="vd_header_row">
      <el-col :md="20">
        <div class="vg_font_20 vd_cpName vd_font_color" @click="collapseChage">
          <span class="vg_pointer">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
          </span>
          <span class="vg_ml_8">上海满恩打样间管理系统</span>
        </div>
      </el-col>
      <el-col :md="4" class="vg_align_right vd_user">
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link vg_pointer vd_font_color vd_cpName">{{ userName }}<i class="el-icon-caret-bottom"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dashboard"><i class="el-icon-s-home"></i>首页</el-dropdown-item>
            <el-dropdown-item command="logout"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import bus from '@/components/common/bus';
import { get } from '@api/request';
import { userAPI } from '@api/modules/user';
import { procAPI } from '@api/modules/proc';
export default {
  name: 'TopHeader',
  props: {},
  data() {
    return {
      collapse: false,
      userName: '谢凡凡',
      new: {},
      mode: null,
      procList: []
    };
  },
  created() {
    this.initData();
    this.getUserMode();
  },
  methods: {
    getUserMode() {
      this.mode = this.$cookies.get('userMode').type;
      if (this.mode == 1) {
        this.getProc();
      }
    },
    getProc() {
      get(procAPI.get_user_proc)
        .then(res => {
          if (res.data.code !== 0) {
            // this.$message.error(res.data.msg)
            return;
          }
          this.procList = res.data.data.form.proc_codes.split(',');
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    initData() {
      this.getUserName();
    },
    //cookie name
    getUserName() {
      if (this.$cookies.get('userInfo')) {
        this.userName = this.$cookies.get('userInfo').stff_name;
      } else {
        this.jump('/login');
      }
    },
    // 侧边栏折叠
    collapseChage() {
      if (this.mode == 0 || this.procList.indexOf('open') != -1) {
        this.collapse = !this.collapse;
        bus.$emit('collapse', this.collapse);
      }
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command === 'logout') {
        get(userAPI.userLogout).then(res => {
          if (res.data.code === 0) {
            this.$cookies.remove('userInfo');
            this.$cookies.remove('push');
            this.$cookies.remove('userMode');
            this.jump('/login');
          }
        });
      } else {
        this.jump('/dashboard');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_header {
  position: relative;
  width: 100%;
  height: 70px;
  // border-radius: 0px 53px 0px 53px;
  // background-image: linear-gradient(120deg,#00e4d0,#5983e8) !important;
  // background-image: linear-gradient(120deg,#FFFFFF,#2dcc83) !important;
  // background: #38a28a;
  // background-image: linear-gradient(to bottom,rgba(131, 153, 129, 0.479),#258986) !important;
  // background-image: linear-gradient(141deg,#9fb8ad 0%,#1fc8db 51%,#2cb5e8 75%);
  background-image: linear-gradient(to bottom, rgb(84 96 83 / 46%), #1f706e) !important;
  .vd_header_row {
    display: flex;
    align-items: center;
    padding: 20px 20px;
  }
}
.vd_user {
  padding-right: 48px;
}
.vd_cpName {
  display: flex;
  align-items: center;
}
.vd_font_color {
  color: $color-white;
  letter-spacing: 2px;
}
</style>
